import React from 'react';
import { Row } from 'reactstrap';
import Logo from '../../../images/siloimages/SecuredDatalogonew.png';
import { Icon, LinkItem } from '../../../components/Component';
import { Link } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';

const SecureDataFooter = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (to, isScroll = true) => {
        if (to.startsWith('/')) {
            navigate(to);
        } else {
            if (location.pathname !== '/') {
                navigate('/');
                setTimeout(() => {
                    if (isScroll) {
                        const element = document.getElementById(to);
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                }, 100);
            } else if (isScroll) {
                const element = document.getElementById(to);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    return (
        <React.Fragment>
            <div className="nk-footer border-0" style={{ backgroundColor: '#E8F7FF' }}>
                <div className="container-fluid">
                    <div className="d-flex pb-3">
                        <div className="col-md-12 align-self-center ">
                            <div className="nk-footer-copyright text-center">
                                <LinkItem to="/" className="scrollto logo-link mx-2">
                                    <img className="align-self-center" src={Logo} alt="logo" width={'40%'} style={{ cursor: 'pointer' }} />
                                </LinkItem>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer-wrap justify-content-center">
                        <div className="container">
                            <Row className="justify-content-lg-around justify-content-md-start pb-2">
                                <h4 className="text-black-50 fw-bold ff-alt d-flex align-content-center justify-content-center mb-2">Our Services</h4>
                                <div className="nk-footer-copyright col-md-3">
                                    <p className="text-black fs-6 fw-bold ff-alt">Software Development</p>
                                    <ul className="list-unstyled ff">
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" style={{ cursor: 'pointer' }} onClick={() => handleNavigation("service")}>Static & Dynamic Web Development</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" style={{ cursor: 'pointer' }} onClick={() => handleNavigation("service")}>E-commerce Web Development</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" style={{ cursor: 'pointer' }} onClick={() => handleNavigation("service")}>ERP / CRM Development</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" style={{ cursor: 'pointer' }} onClick={() => handleNavigation("service")}>Mobile App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nk-footer-copyright col-md-2">
                                    <p className="fw-bold ff-alt fs-6 text-black">Creative & Design</p>
                                    <ul className="list-unstyled ff">
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>UI/UX Design</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Graphic Design</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>3D Design</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Game Design</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nk-footer-copyright col-md-3">
                                    <p className="text-black fs-6 fw-bold ff-alt">Digital Marketing & Management</p>
                                    <ul className="list-unstyled ff">
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>SEO & Digital Marketing</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Social Media Management & Creation</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Brand Evolution</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Content Marketing</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nk-footer-copyright col-md-4">
                                    <p className="text-black fs-6 fw-bold ff-alt">Web Infrastructure & Management</p>
                                    <ul className="list-unstyled ff">
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Web Hosting</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Domain Registration & Management</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Dedicated Server Hosting</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="service" className="ff-alt" spy smooth duration={500} style={{ cursor: 'pointer' }}>Website Maintenance & Support</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </div>
                        <div className="container d-md-flex justify-content-start">
                            <div className="nk-footer-copyright pb-3 d-flex align-items-center">
                                <h4 className="text-black-50 fw-bold ff-alt fs-4 pe-3">Contact Us</h4>
                                <ul className="list-unstyled">
                                    <li className="px-2">
                                        <Icon name={"call"} />
                                        <a href="tel:833-468-6648" className="px-2 ff-alt">833-468-6648</a>
                                    </li>
                                    <li className="px-2">
                                        <Icon name={"mail"} />
                                        <a href="mailto:support@silocloud.io" className="px-2 ff-alt">support@silocloud.io</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-footer-copyright d-flex align-items-center">
                                <h4 className="text-black-50 fw-bold ff-alt fs-4 pe-3">Follow Us</h4>
                                <div className="d-flex ps-3 ps-md-0">
                                    <a href="https://www.facebook.com/silocloud" target="_blank" rel="noopener noreferrer" className="socialbtn facebook">
                                        <Icon name={"facebook-fill"} className={"fs-1"} />
                                    </a>
                                    <a href="https://twitter.com/cloud_silo" target="_blank" rel="noopener noreferrer" className="socialbtn twitter">
                                        <Icon name={"twitter-round"} className={"fs-1"} />
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCkzrRrUo6NkzkFUvMpfU0Iw" target="_blank" rel="noopener noreferrer" className="socialbtn youtube">
                                        <Icon name={"youtube-round"} className={"fs-1"} />
                                    </a>
                                    <a href="https://www.instagram.com/silocloud/" target="_blank" rel="noopener noreferrer" className="socialbtn instagram">
                                        <Icon name={"instagram-round"} className={"fs-1"} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SecureDataFooter;
