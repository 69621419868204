import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Block, RSelect } from '../../../components/Component';
import { Button, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { serviceData } from "../../components/forms/SelectData";
import makeAnimated from "react-select/animated";
import tabimg1 from '../../../images/siloimages/Contactimg.png';
import tabimg2 from '../../../images/siloimages/E11.png';
import Required from '../../components/Required';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const initialdata = {
    firstname: "",
    lastname: "",
    phoneno: "",
    services: [],
    email_user: "",
    description: "",
}

const Contactus = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState(initialdata);
    const [errors, setErrors] = useState({});

    const recaptchaRef = useRef(null);
    const animatedComponents = useMemo(() => makeAnimated(), []);

    const handleCaptcha = useCallback((value) => {
        setCaptchaValue(value);
    }, []);


    const handlePhoneChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            phoneno: value,
        }));

        if (!value || !isValidPhoneNumber(value)) {
            setErrors(prevState => ({ ...prevState, phoneno: 'Please enter a valid phone number.' }));
        } else {
            setErrors(prevState => ({ ...prevState, phoneno: '' }));
        }
    };

    const handleChange = useCallback((e, name) => {
        let value;
        if (e && e.target) {
            ({ name, value } = e.target);
        } else if (Array.isArray(e)) {
            value = e;
        } else {
            value = e;
        }

        setFormData(prevState => ({ ...prevState, [name]: value }));
        setErrors(prevState => ({ ...prevState, [name]: '' }));

        if (name === 'email_user') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setErrors(prevState => ({ ...prevState, [name]: 'Enter a valid email address' }));
            }
        }
    }, []);

    const formSubmit = useCallback(async (formData) => {
        const formPayload = new FormData();

        formPayload.append("note", formData.description);
        formPayload.append("email", formData.email_user);
        formPayload.append("first_name", formData.firstname);
        formPayload.append("last_name", formData.lastname);
        formPayload.append("phone", formData.phoneno);

        Object.entries(formData).forEach(([key, value]) => {
            if (key !== 'description' && key !== 'email_user' && key !== 'firstname' && key !== 'lastname' && key !== 'phoneno') {
                formPayload.append(key, key === 'services' ? JSON.stringify(value) : value);
            }
        });

        try {
            const response = await fetch("https://api.silocloud.io/api/v1/public/addContactus", {
                method: "POST",
                body: formPayload,
            });
            const data = await response.json();
            // console.log(data);
        } catch (error) {
            toast.error("Error submitting form:", error);
            console.error("Error submitting form:", error);
            return;
        }
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            if (!formData.phoneno || !isValidPhoneNumber(formData.phoneno)) {
                setErrors(prevState => ({ ...prevState, phoneno: 'Please enter a valid phone number.' }));
                setIsLoading(false);
                return;
            }
            if (!captchaValue) {
                toast.error("Please complete the reCAPTCHA");
                return;
            }

            await new Promise((resolve) => setTimeout(resolve, 3000));

            setIsSubmitted(true);
            toast.success("Thank you for contacting us, We will reach back to you very soon!", {
                autoClose: 3000,
            });

            await formSubmit(formData);
            setFormData(initialdata);
            setCaptchaValue(null);
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }

            setTimeout(() => {
                setIsSubmitted(false);
            }, 30000);

        } catch (error) {
            toast.error('Error submitting form:', error);
        } finally {
            setIsLoading(false);
        }
    }, [captchaValue, formData, formSubmit]);

    return (
        <React.Fragment>
            <Block>
                <div className="container-fluid position-relative" id='contact'>
                    <div className='d-flex justify-content-center mx-md-5 py-md-5 py-4'>
                        <h3 className="ff-alt fw-semibold text-capitalize text-center" style={{ color: "#17253D" }}>
                            Talk to us about your project
                        </h3>
                    </div>
                    <Row className={`py-3 g-2`}>
                        <Col md={12} lg={6}>
                            <div className="conatiner p-0 z-1 position-relative ">
                                <div className='d-flex justify-content-center rounded-5'>
                                    <img src={tabimg1} alt="" className='rounded-5' />
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={6} className={`align-self-center z-3`}>
                            <div className="container-fluid" id='contactus'>
                                <Row className="justify-content-center" data-aos="fade-down" data-aos-duration="3000">
                                    <Col md={12} className="px-0" id='formim'>
                                        {isSubmitted && <div className="text-success d-flex justify-content-center pb-4 fs-5">Thank you! We will be in touch soon.</div>}
                                        <form className='' id='feedbackForm' onSubmit={handleSubmit}>
                                            <div className="form-row d-md-flex pb-2 justify-content-around">
                                                <div className="form-group col-md-6 px-2">
                                                    <label htmlFor="input1" className='form-label'>First Name:<Required /></label>
                                                    <input type="text" className="form-control" id="input1" name="firstname" placeholder='Enter First Name' onChange={handleChange} value={formData.firstname} required />
                                                </div>
                                                <div className="form-group col-md-6 px-2 ">
                                                    <label htmlFor="input2" className='form-label'>Last Name:<Required /></label>
                                                    <input type="text" className="form-control" id="input2" name="lastname" placeholder='Enter Last Name' onChange={handleChange} value={formData.lastname} required />
                                                </div>
                                            </div>
                                            <div className="form-row d-md-flex pb-2 justify-content-around">
                                                <div className="form-group col-md-6 px-2 ">
                                                    <label htmlFor="input3" className='form-label'>Email:<Required /></label>
                                                    <input type="email" className="form-control" id="input3" name="email_user" placeholder='Enter Email' onChange={handleChange} value={formData.email_user} required />
                                                    {errors.email_user && <div className="text-danger">{errors.email_user}</div>}
                                                </div>
                                                <div className="form-group col-md-6 px-2">
                                                    <label htmlFor="input4" className='form-label'>Phone No:<Required /></label>
                                                    <div className='phone-input-container'>
                                                        <PhoneInput
                                                            name="phoneno"
                                                            placeholder="Enter phone number"
                                                            defaultCountry="US"
                                                            value={formData.phoneno}
                                                            onChange={handlePhoneChange}
                                                            className="form-control border-0 p-0 bg-transparent"
                                                            required
                                                        />
                                                    </div>
                                                    {errors.phoneno && <div className="text-danger">{errors.phoneno}</div>}
                                                </div>
                                            </div>
                                            <div className="form-row d-md-flex pb-2 justify-content-around">
                                                <div className="form-group col-md-12 px-2 mb-3">
                                                    <label className="form-label">Select Services:<Required /></label>
                                                    <RSelect
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        // defaultValue={[]}
                                                        isMulti
                                                        options={serviceData}
                                                        name="services"
                                                        onChange={(selectedOption) => handleChange(selectedOption, 'services')}
                                                        value={formData.services}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row d-md-flex pb-2 justify-content-around">
                                                <div className="form-group col-md-12 px-2 mb-3">
                                                    <label htmlFor="input7" className='form-label'>How can we help you?<Required /></label>
                                                    <textarea type="textarea" placeholder='Enter Message' className="form-control" id="input6" name="description" onChange={handleChange} value={formData.description} required />
                                                </div>
                                            </div>
                                            <div className="form-row d-md-flex pb-2 justify-content-center">
                                                <div className="form-group col-md-6 px-2 justify-content-center">
                                                    <ReCAPTCHA
                                                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                                                        onChange={handleCaptcha}
                                                        ref={recaptchaRef}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row d-md-flex pb-2 justify-content-center">
                                                <div className="form-group d-md-flex pb-2 px-2 justify-content-around">
                                                    <Button type="submit" className='px-5 py-1 ff-alt fw-medium button' disabled={isLoading}>{isLoading ? 'Loading...' : 'Submit'}</Button>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center ms-md-5">
                                                <div>
                                                    <p className='ff-alt fs-6 fw-medium' style={{ color: '#17253D' }}>What happens after sending a message?</p>
                                                    <ul className='list ff-alt' style={{ color: '#17253D' }}>
                                                        <li>We’ll email over an introductory pack straight away</li>
                                                        <li>A member of the team will be in touch within 24 hours. </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className='A4 d-md-inline-block d-none'>
                        <img src={tabimg2} alt="" width='50%' />
                    </div>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default Contactus
